import './App.css';
import mrbacon from './media/MrBacon.JPG';

function App() {

return (
  <div className="app-body">
    <h2 className="app-header">No code, no problem!</h2>
    <p className="app-text">
      Hola! I'm Jen and I'm a full stack developer. I specialize in
      developing business solutions for startups using no-code / low-code tools
      such as Retool, Make, Zapier, Airtable (and the likes) 🤓.
      <br /> <br />
      I'm still working on my site but feel free to shoot a message{" "}
      <a href={"mailto:hi@jenpineda.com?subject=Hello"}>here</a> to connect.
      <br /> <br />
      <hr></hr>
      <div class="row">
        <div class="column-1">
          <br></br>
          <br></br>
          <br></br>
          Here's a doodle of our dog,{" "}
          <b>
            <i>Mr. Cris P. Bacon (aka Mr. Bacon)</i>
          </b>
          , if you want to stay on the page:
        </div>
        <div class="column-2">
          <img src={mrbacon} title="Mr. Bacon" alt="Mr. Bacon" width="130" />
        </div>
      </div>
      <br />
    </p>
  </div>
);
}

export default App;
